import { Component, isDevMode } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { TranslationComponent } from '../../translation/translation.component';
import { environment } from '../../../environments/environment';
import { MultiloginDialogComponent } from '../dialogs/multilogin-dialog/multilogin-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  token: string;
  error: boolean;
  textoModal: string;
  usuario: string;
  contrasena: string;
  datosLoginValidation: any;
  loadingSpinnerLogin = false;
  botonAccederVisibleLogin = true;
  messageLogin: boolean;
  messageRecuperarPass: boolean;
  respuesta: any;
  algunaVezLogueado: any;
  CambioPassword: any;
  IsAdministrador: any;
  UserAccess: any;
  TextoInicioSesion: any;
  idiomas: boolean;
  peticionAPIs = environment.peticionAPIs;
  multilogin = environment.multiapi;
  UrlApi = environment.urlApi;
  urlPoliticaPrivacidad = environment.urlPoliticaPrivacidad;
  TextoLegal = environment.TextoLegal;
  contadorintentosmultiapi = 0;
  numLoginsCorrectos = 0;

  constructor(
    private _loginService: LoginService,
    private _funcionesService: FuncionesService,
    private router: Router,
    public dialog: MatDialog,
    public rutaActiva: ActivatedRoute,
  ) {
    this.error = false;
    this.token = this._loginService.token;

    // if (isDevMode) {
    //   this.usuario = 'A96184882';
    //   this.contrasena = '1gV9679A';
    // }
  }

  validaLogin(f: NgForm) {
    this.usuario = this.usuario.trim();
    // Validamos que solo acceda cuando el formulario es valido, es decir que todos los campos pasen rellenados
    if (f.valid) {
      if( this.usuario.indexOf("'")>= 0 || this.contrasena.indexOf("'")>= 0 ){
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            texto: 'Usuario o contraseña contienen caracteres no permitidos.',
            titulo: 'Error en el login'
          }
        });
      } else {
        for (var i = 0; i < this.peticionAPIs.length; i++) {
          this.peticionAPIs[i].IsClienteExistente=false;
        }

        if (this.multilogin) { //RESOLVER MINIPROBLEMA DE PASSWORD.COMPONENT.TS
          this.contadorintentosmultiapi = 0;
          this.numLoginsCorrectos = 0;
          for (const peticion of this.peticionAPIs) {
            this._loginService.getToken(peticion.urlApi, this.usuario, this.contrasena).subscribe(
              data => {
                data.Token;
                this.token = data.Token;
                if (data["Token"] != "Usuario o contraseña no encontrados") {
                  peticion.IsClienteExistente = true;
                  peticion.token = data["Token"];
                  this.numLoginsCorrectos++;
                }

                if (this.peticionAPIs.length - 1 == this.contadorintentosmultiapi) {
                  if (this.numLoginsCorrectos == 0) {
                    this.botonAccederVisibleLogin = true;
                    this.loadingSpinnerLogin = false;

                    // this.dialog.open(LoginDialogComponent, {
                    //   width: '400px',
                    //   data: {
                    //     texto: 'Usuario o contraseña incorrectos.',
                    //     titulo: 'Error en el login'
                    //   }
                    // });
                    if(environment.defaultLang=='cat'){
                      this.dialog.open(LoginDialogComponent, {
                        width: '400px',
                        data: {
                          titulo: 'Error en el login',
                          texto: 'Usuari o contrasenya incorrectes.',


                        }
                      });

                    }else{
                      this.dialog.open(LoginDialogComponent, {
                        width: '400px',
                        data: {
                          titulo: 'Error en el login',
                          texto: 'Usuario o contraseña incorrectos.',


                        }
                      });
                    }
                  }

                  if (this.numLoginsCorrectos == 1) {
                    for (var i = 0; i < this.peticionAPIs.length; i++) {
                      if (this.peticionAPIs[i].IsClienteExistente) {
                        environment.urlApi = this.peticionAPIs[i].urlApi;
                        environment.urlCch = this.peticionAPIs[i].urlCch;
                        environment.cryptoKey = this.peticionAPIs[i].cryptoKey;
                        environment.cryptoIV = this.peticionAPIs[i].cryptoIV;
                        environment.companyName = this.peticionAPIs[i].companyName;

                        this.validacion(this.usuario, this.contrasena, this.peticionAPIs[i].token);

                      }
                    }
                  }

                  if (this.numLoginsCorrectos > 1) {

                    var ClienteExistente = [];

                    for (var i = 0; i < this.peticionAPIs.length; i++) {
                      if (this.peticionAPIs[i].IsClienteExistente) {
                        ClienteExistente.push(this.peticionAPIs[i]);
                      }
                    }

                    var DialogoParaCompanyName = this.dialog.open(MultiloginDialogComponent, {
                      width: '400px',
                      data: {
                        ClienteExistente: ClienteExistente,
                      }
                    });
                    DialogoParaCompanyName.afterClosed().subscribe(companyName => {
                      this.loginmultiapi(companyName);
                    })
                    DialogoParaCompanyName.backdropClick().subscribe(data => {

                      this.botonAccederVisibleLogin = true;
                      this.loadingSpinnerLogin = false;
                    })
                  }
                }
                this.contadorintentosmultiapi++;
              });
          }
        } else {
          this._loginService.getToken(this.UrlApi, this.usuario, this.contrasena).subscribe(
            data => {
              data.Token;
              this.token = data.Token;
              this.validacion(this.usuario, this.contrasena, this.token)
            });
        }

        this.botonAccederVisibleLogin = false;
        this.loadingSpinnerLogin = true;
      }
    }
  }

  validacion(usuario, contrasena, token) {

    this._loginService.getValidation(usuario, contrasena, token).subscribe(
      data => {
        localStorage.clear();
        localStorage.setItem('cryptoKey', environment.cryptoKey);
        localStorage.setItem('cryptoIV', environment.cryptoIV);
        localStorage.setItem('urlApi', environment.urlApi);
        localStorage.setItem('idioma', environment.defaultLang);
        this.datosLoginValidation = this._funcionesService.decodificarToken(data);
        if (this.datosLoginValidation.Valido === true) {

          /**
           * Guardamos TipoCliente en localStorage
           * Cuando TipoCliente = 1, es monopunto
           * Cuando TipoCliente = 2, es multipunto
           * Cuando TipoCliente = 3, es AdminFincas
           */

          localStorage.setItem('TipoCliente', this.datosLoginValidation.TipoCliente);
          /** Hacemos la llamada de DatosTitular y entramos a la OV */
          this._loginService.getLogin(this.usuario, this.contrasena).subscribe(
            datosLogin => {
              this.botonAccederVisibleLogin = true;
              this.loadingSpinnerLogin = false;
              // Cargo los datos en la variable del servicio para poder compartirlo entre pestañas
              this._loginService.datosTitular = this._funcionesService.decodificarToken(datosLogin);


              localStorage.setItem('urlCch', environment.urlCch);
              localStorage.setItem('companyName', environment.companyName);
              localStorage.setItem('usuarioCambioPass', this.usuario);
              localStorage.setItem('passCambioPass', this.contrasena);
              localStorage.setItem('u', this._funcionesService.codificarDatos(this.usuario));
              localStorage.setItem('p', this._funcionesService.codificarDatos(this.contrasena));
              localStorage.setItem('token', this._loginService.token);
              localStorage.setItem('isAdminFincas', this._loginService.datosTitular.IsAdministrador);

              if (this._loginService.datosTitular.IsAdministrador === true) {
                localStorage.setItem('idCliente', JSON.stringify(this._loginService.datosTitular.IdAdministrador));
              } else {
                localStorage.setItem('idCliente', JSON.stringify(this._loginService.datosTitular.IdCliente));
              }
              localStorage.setItem('CambioPassword', (this._loginService.datosTitular.CambioPassword) ? this._loginService.datosTitular.CambioPassword : false);
              localStorage.setItem('IsAdministrador', this._loginService.datosTitular.IsAdministrador);

              // Si CambioPassword es false, obliga a cambiar la contraseña por primera vez

              this.CambioPassword = localStorage.getItem('CambioPassword');
              // this.CambioPassword = localStorage.getItem('CambioPassword');
              this.IsAdministrador = localStorage.getItem('IsAdministrador');

              if (this.CambioPassword === 'False' && this.IsAdministrador === 'false') {
                // console.log('redirigiendo a password');
                this.router.navigate(['/password']);

              } else {
                // console.log('redirigiendo a resumen');
                this.router.navigate(['/resumen']);
              }

            }
          );
        } else {
          this.botonAccederVisibleLogin = true;
          this.loadingSpinnerLogin = false;
          // this.dialog.open(LoginDialogComponent, {
          //   width: '400px',
          //   data: {
          //     texto: 'Usuario o contraseña incorrectos.',
          //     titulo: 'Error en el login'
          //   }
          // });
          if(environment.defaultLang=='cat'){
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                titulo: 'Error en el login',
                texto: 'Usuari o contrasenya incorrectes.',


              }
            });

          }else{
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                titulo: 'Error en el login',
                texto: 'Usuario o contraseña incorrectos.',


              }
            });
          }
        }
      },
      err => {
        // this.dialog.open(LoginDialogComponent, {
        //   width: '400px',
        //   data: {
        //     texto: 'Usuario o contraseña incorrectos.',
        //     titulo: 'Error en el login'
        //   }
        // });
        if(environment.defaultLang=='cat'){
          this.dialog.open(LoginDialogComponent, {
            width: '400px',
            data: {
              titulo: 'Error en el login',
              texto: 'Usuari o contrasenya incorrectes.',


            }
          });

        }else{
          this.dialog.open(LoginDialogComponent, {
            width: '400px',
            data: {
              titulo: 'Error en el login',
              texto: 'Usuario o contraseña incorrectos.',


            }
          });
        }
        this.botonAccederVisibleLogin = true;
        this.loadingSpinnerLogin = false;
      }
    );
  }

  loginmultiapi(companySelected) {

    for (var i = 0; i < this.peticionAPIs.length; i++) {
      if (this.peticionAPIs[i].companyName == companySelected) {

        environment.urlApi = this.peticionAPIs[i].urlApi;
        environment.urlCch = this.peticionAPIs[i].urlCch;
        environment.cryptoKey = this.peticionAPIs[i].cryptoKey;
        environment.cryptoIV = this.peticionAPIs[i].cryptoIV;
        environment.companyName = this.peticionAPIs[i].companyName;

        this.validacion(this.usuario, this.contrasena, this.peticionAPIs[i].token);
      }
    }
  }

  ngOnInit() {


      // Mostramos mensaje si la variable messageLogin llega a true

    if(!(Object.keys(this.rutaActiva.snapshot.params).length === 0)) {
      this.usuario = this.rutaActiva.snapshot.params['user'];
      this.contrasena = this.rutaActiva.snapshot.params['pass'];
      this._loginService.getToken(this.UrlApi,this.usuario, this.contrasena).subscribe(
        data => {
          // console.log(data);
          data.Token;
          this.token = data.Token;
         this.validacion(this.usuario, this.contrasena, this.token)
        });

      // this.validacion(this.usuario,  atob(this.contrasena),this.token);
      // this.contrasena = '';
    }
    // localStorage.clear();
    if (environment.messageLogin) {
      this.messageLogin = true;
    }
    if (environment.messageRecuperarPass) {
      this.messageRecuperarPass = true;
    }
    this.idiomas = environment.idiomas;
    this.UserAccess = environment.UserAccess;
    this.TextoInicioSesion = environment.TextoInicioSesion;
  }
}
