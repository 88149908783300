import { Component, OnInit } from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { CchDialogComponent } from '../../components/dialogs/cch-dialog/cch-dialog.component';
import { LoginService } from '../../services/login/login.service';
import * as Highcharts from 'highcharts';
import { GraficosService } from './../../services/config/graficos/graficos.service';
import { CchService } from './../../services/cch/cch.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { ValidacionesCchService } from '../../services/config/validaciones/cch/validaciones-cch.service';
import * as moment from 'moment';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { environment } from '../../../environments/environment';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-cch',
  templateUrl: './cch.component.html',
  styleUrls: ['./cch.component.scss']
})
export class CchComponent implements OnInit {
  mesesgraficocch = environment.mesesgraficocch;
  // maxCUPSgraficoCCH = environment.maxCUPSgraficoCCH;
  tipoGraficoSelected = 'Comparativa';
  Highcharts = Highcharts;
  Highcharts2 = Highcharts;
  options: any;
  // TODO: Hacer una interface para los graficos que el series lo cargue a 0 para evitar el series undefined
  chartOptions: any = { series: [{ data: [] }] };
  chartOptions2: any = { series: [{ data: [] }] };
  // chartOptions: any;
  // chartOptions2: any;
  datosCch: any;
  datosFact: any;
  arrayActiva: any[] = [];
  arrayReactiva: any[] = [];
  arrayCategorias: any[] = [];
  arrayCategorias2: any[] = [];
  arrayCups: any[] = [];
  CupsCch: any;
  TamanoListadoCupsCliente: number;
  FecInicio: any = moment().subtract(this.mesesgraficocch, 'M').format();
  FecFin: any = moment().format();
  FecInicioAux: any;
  FecFinAux: any = moment().format();

  updateFlag: boolean;
  mostrar: boolean;
  mostrar2: boolean;
  oneToOneFlag: boolean;
  isTcurvas: boolean;
  CambiaTituloCChGraph: any;

  arrayCupsSeleccionados: any = [];
  arrayCodigoContratoSeleccionados: any = [];

  selectedCups = new FormControl();
  cupsFiltered: string[] = this.arrayCups;
  cupsMonopunto: any;
  longitud: any;
  arraySumatorioSeries: any = [];
  CodigoCupsActual: any = [];

  CchAgregables: boolean;
  bloqueocheckCCH = false;
  muestracheckmarcarCups: boolean;

  DobleGraficoConsumosPorHora: boolean;

  saveInstance:any;

  public href: string = '';

  constructor(
    private _GraficosService: GraficosService,
    private _CchService: CchService,
    private _funcionesService: FuncionesService,
    public dialog: MatDialog,
    private _loginService: LoginService,
    private _validacionesCch: ValidacionesCchService,
  ) {}



  ngOnInit() {
    this.mostrar = false;
    this.mostrar2 = false;

    // Cambiamos el tipo de curva llega a true
    this.isTcurvas = environment.isTcurvas;

    this.CambiaTituloCChGraph = environment.CambiaTituloCChGraph;
    this.CchAgregables = this._validacionesCch.CchAgregables;
    this.updateFlag = this.oneToOneFlag = false;

    this.DobleGraficoConsumosPorHora = environment.DobleGraficoConsumosPorHora;

    this._loginService.getLogin(
      this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('u'))),
      this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('p'))))
      .subscribe(async data => {

        const datosTitular = this._funcionesService.decodificarToken(data);

        if (datosTitular.CUPS && datosTitular.CUPS.length > 1) {

          // Cargar los CUPS del usuario en el desplegable
          this.longitud = datosTitular.CUPS.length;

          if (this.longitud > 1 && this.longitud < environment.maxCUPSgraficoCCH){
            this.muestracheckmarcarCups = true;
          } else {
            this.muestracheckmarcarCups = false;
          }

          datosTitular.CUPS.forEach(Cups => {
            if ({ Cups }.Cups['Entorno'] != 'Gas') {
              this.arrayCups.push({ Cups });
            }
          });

          /**
           * COMO ES MULTIPUNTO CARGO LA GRAFICA CON LA SERIES [] PARA QUE SE VEA EL ERROR DE DATOS
           **/

          this.options = JSON.parse(this._GraficosService.graficosOption);
          this.chartOptions = {
            lang: this.options,
            chart: { type: 'line',
                     zoomType: 'xy',
                     events: {
                      load: function(event) {
                        this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 3}).add();
                      }
                     }
                    },
            title: { text: 'CCH' },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    'printChart',
                    'separator',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS'
                  ]
                }
              }

            },
            subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
            xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
            yAxis: { title: { text: 'Consumo (kWh)' } },
            series: []
          };

          // FACT
          this.options = JSON.parse(this._GraficosService.graficosOption);
          this.chartOptions2 = {
            lang: this.options,
            chart: {  type: 'line',
                      zoomType: 'xy',
                      events: {
                        load: function(event) {
                          this.renderer.image("/assets/img/logo.png",10,10,150,50)
                          .attr({
                            zIndex: 99999999,
                            // width: 200px;
                          })
                          .add();
                        }
                    }
            },
            title: { text: 'FACTURADAS' },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    'printChart',
                    'separator',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS'
                  ]
                }
              }

            },
            subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
            xAxis: { categories: this.arrayCategorias2, crosshair: true, description: 'Descripción' },
            yAxis: { title: { text: 'Consumo (kWh)' } },
            series: []
          };


        } else if (datosTitular.CUPS.length === 1) {

          this.longitud = datosTitular.CUPS.length;
          this.cupsMonopunto = datosTitular.CUPS[0].CodigoCups;
          // TODO Meterlo como array y ya listo
          this.arrayCupsSeleccionados.push({"CodigoCups": datosTitular.CUPS[0].CodigoCups});
          this.arrayCodigoContratoSeleccionados.push(datosTitular.CUPS[0].CodigoContrato);
          this.busquedaCchPorFechas(this.FecInicio,this.FecFin,  this.arrayCupsSeleccionados, this.arrayCodigoContratoSeleccionados, this.tipoGraficoSelected, false);

        } else {

          // No tiene CUPS
          const options = {
            width: '400px',
            data: {
              texto: 'No se han encontrado CUPS para el usuario actual',
              titulo: 'Curvas de Carga Horaria'
            }
          };
          this.dialog.open(LoginDialogComponent, options);
        }
      });
  }




  busquedaCchPorFechas(FecInicio, FecFin, Cups, CodigoContrato, tipoGrafico, reinicio) {
    // console.log(Cups);
    // console.log(CodigoContrato);
    const seriesAdd = [];
    const seriesAdd2 = [];
    if(Cups.length > 0){
      if(this.isTcurvas) {
        if (!environment.cchWitchCups) {
          this.mostrar = true;
        // CARGA DE CURVAS ENVIADO CodigoContrato | OPCION A
        this._CchService.getTCurvas(CodigoContrato, moment(FecInicio).set({hour:1,minute:0}).format(), moment(FecFin).format()).subscribe(
          async data => {
            this.mostrar = false;
            this.datosCch = this._funcionesService.decodificarToken(data);
            if (this.datosCch !== undefined) {
              this.chartOptions = {
                lang: this.options,
                chart: { type: 'line',
                    zoomType: 'xy',
                    events: {
                    load: function(event) {
                      this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                    }
                  }
                },
                title: { text: 'CCH' },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                      ]
                    }
                  }
                },
                subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
                yAxis: { title: { text: 'Consumo (kWh)' } },
                series: [],
                credits: {
                  enabled: false
                }
              };
              /**
               * COMPARATIVAS
               */
              if (tipoGrafico === 'Comparativa') {
                this.arrayCategorias = [];
                for (const i of this.datosCch) {
                  // Para cada CUPS cargo los datos vacíos
                  const arrayActiva = [];
                  const arrayReactiva = [];
                  for (const e of i.ListaCCH) {
                    arrayActiva.push(Number(e.ACTIVA));
                    arrayReactiva.push(Number(e.REACTIVA));
                    this.arrayCategorias.push(moment(e.FECHAMED).format('DD MMM YYYY (HH:mm)').toString());
                  }
                  seriesAdd.push(
                    { name: `Activa ${i.CodigoCUPS}`, data: arrayActiva },
                    { name: `Reactiva ${i.CodigoCUPS}`, data: arrayReactiva }
                  );
                }
                this.options = JSON.parse(this._GraficosService.graficosOption);
                let graphtitle = 'CCH';
                if (this.CambiaTituloCChGraph){
                  graphtitle = 'Detalle de consumos eléctricos';
                }
                this.chartOptions = {
                  lang: this.options,
                  chart: { type: 'line',
                          zoomType: 'xy',
                          events: {
                            load: function(event) {
                              this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                            }
                          }
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }
                  },
                  saveInstance(chartInstance): void { this.chart = chartInstance; },
                  title: { text: graphtitle },
                  subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                  xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
                  yAxis: { title: { text: 'Consumo (kWh)' } },
                  series: seriesAdd,
                  credits: {
                    enabled: false
                  }
                };
                this.oneToOneFlag = this.updateFlag = true;
              }
              if (reinicio == true){
                this.isTcurvas = false;
              }
            }
          },
          (error) => {
            this.mostrar = false;
            const options = {
              width: '400px',
              data: {
                texto: 'Se ha producido un error en la carga de las curvas.',
                titulo: 'Curvas de Carga Horaria'
              }
            };
            this.dialog.open(LoginDialogComponent, options);
          }
        );
        } else {
            // CARGA DE CURVAS ENVIADO LOS CUPS | OPCION B
            // console.log(Cups);
            var tempCups = [];
            Cups.forEach( function(item, index, array) {
                tempCups.push(item.CodigoCups);
            });

            this.mostrar2 = true;

            this._CchService.getTCurvasWithCups(tempCups, moment(FecInicio).set({hour:1,minute:0}).format(), moment(FecFin).format()).subscribe(
              async data => {
                this.mostrar2 = false;
                this.datosCch = this._funcionesService.decodificarToken(data);
                // console.log(this.datosCch);
                if (this.datosCch !== undefined) {
                  this.chartOptions = {
                    lang: this.options,
                    chart: { type: 'line',
                            zoomType: 'xy',
                            events: {
                              load: function(event) {
                                this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                              }
                            }
                    },
                    title: { text: 'CCH' },
                    exporting: {
                      buttons: {
                        contextButton: {
                          menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                            'separator',
                            'downloadCSV',
                            'downloadXLS'
                          ]
                        }
                      }
                    },
                    subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                    xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
                    yAxis: { title: { text: 'Consumo (kWh)' } },
                    series: [],
                    credits: {
                      enabled: false
                    }
                  };
                  /**
                   * COMPARATIVAS
                   */
                  if (tipoGrafico === 'Comparativa') {
                    this.arrayCategorias = [];
                    for (const i of this.datosCch) {
                      // Para cada CUPS cargo los datos vacíos
                      const arrayActiva = [];
                      const arrayReactiva = [];
                      var activa;
                      var reactiva;
                      for (const e of i.ListaCCH) {
                        activa = e.ACTIVA.replace(',','.');
                        reactiva = e.REACTIVA.replace(',','.');
                        arrayActiva.push(Number(activa));
                        arrayReactiva.push(Number(reactiva));
                        this.arrayCategorias.push(moment(e.FECHAMED).format('DD MMM YYYY (HH:mm)').toString());
                      }
                      seriesAdd.push(
                        { name: `Activa ${i.CodigoCUPS}`, data: arrayActiva },
                        { name: `Reactiva ${i.CodigoCUPS}`, data: arrayReactiva }
                      );
                    }
                    this.options = JSON.parse(this._GraficosService.graficosOption);
                    let graphtitle = 'CCH';
                    if (this.CambiaTituloCChGraph){
                      graphtitle = 'Detalle de consumos eléctricos';
                    }
                    this.chartOptions = {
                      lang: this.options,
                      chart: { type: 'line',
                              zoomType: 'xy',
                              events: {
                                load: function(event) {
                                  this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                                }
                              }
                      },
                      exporting: {
                        buttons: {
                          contextButton: {
                            menuItems: [
                              'printChart',
                              'separator',
                              'downloadPNG',
                              'downloadJPEG',
                              'downloadPDF',
                              'downloadSVG',
                              'separator',
                              'downloadCSV',
                              'downloadXLS'
                            ]
                          }
                        }
                      },
                      saveInstance(chartInstance): void { this.chart = chartInstance; },
                      title: { text: graphtitle },
                      subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                      xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
                      yAxis: { title: { text: 'Consumo (kWh)' } },
                      series: seriesAdd,
                      credits: {
                        enabled: false
                      }
                    };
                    this.oneToOneFlag = this.updateFlag = true;
                  }
                  if (reinicio == true){
                    this.isTcurvas = false;
                  }
                }
              },
              (error) => {
                this.mostrar2 = false;
                const options = {
                  width: '400px',
                  data: {
                    texto: 'Se ha producido un error en la carga de las curvas.',
                    titulo: 'Curvas de Carga Horaria'
                  }
                };
                this.dialog.open(LoginDialogComponent, options);
              }
          );
        }

        // CURVAS FACT
        if(this.DobleGraficoConsumosPorHora) {

          this.mostrar2 = true;
          this._CchService.getFact(Cups, moment(FecInicio).set({hour:1,minute:0}).format(), moment(FecFin).format()).subscribe(
            async data => {
              this.mostrar2 = false;
              this.datosFact = this._funcionesService.decodificarToken(data);
              // console.log(this.datosFact);
              if (this.datosFact !== undefined) {
                this.chartOptions2 = {
                  lang: this.options,
                  chart: { type: 'line',
                          zoomType: 'xy',
                          events: {
                              load: function(event) {
                                this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                              }
                          }
                  },
                  title: { text: 'FACTURADAS' },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }
                  },
                  subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                  xAxis: { categories: this.arrayCategorias2, crosshair: true, description: 'Descripción' },
                  yAxis: { title: { text: 'Consumo (kWh)' } },
                  series: [],
                  credits: {
                    enabled: false
                  }
                };
                /**
                 * COMPARATIVAS
                 */
                if (tipoGrafico === 'Comparativa') {
                  this.arrayCategorias2 = [];
                  for (const i of this.datosFact) {
                    // Para cada CUPS cargo los datos vacíos
                    const arrayActiva = [];
                    const arrayReactiva = [];
                    for (const e of i.ListaCCH) {
                      arrayActiva.push(Number(e.ACTIVA));
                      arrayReactiva.push(Number(e.REACTIVA));
                      this.arrayCategorias2.push(moment(e.FECHAMED).format('DD MMM YYYY (HH:mm)').toString());
                    }
                    seriesAdd2.push(
                      { name: `Activa ${i.CodigoCUPS}`, data: arrayActiva },
                      { name: `Reactiva ${i.CodigoCUPS}`, data: arrayReactiva }
                    );
                  }
                  this.options = JSON.parse(this._GraficosService.graficosOption);
                  this.chartOptions2 = {
                    lang: this.options,
                    chart: { type: 'line',
                            zoomType: 'xy',
                            events: {
                              load: function(event) {
                                this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                              }
                            }
                    },
                    exporting: {
                      buttons: {
                        contextButton: {
                          menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                            'separator',
                            'downloadCSV',
                            'downloadXLS'
                          ]
                        }
                      }
                    },
                    saveInstance(chartInstance): void { this.chart = chartInstance; },
                    title: { text: 'FACTURADAS' },
                    subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                    xAxis: { categories: this.arrayCategorias2, crosshair: true, description: 'Descripción' },
                    yAxis: { title: { text: 'Consumo (kWh)' } },
                    series: seriesAdd2,
                    credits: {
                      enabled: false
                    }
                  };
                  this.oneToOneFlag = this.updateFlag = true;
                }
              }
            },
            (error) => {
              this.mostrar2 = false;
              const options = {
                width: '400px',
                data: {
                  texto: 'Se ha producido un error en la carga de las curvas.',
                  titulo: 'Curvas de Carga Horaria'
                }
              };
              this.dialog.open(LoginDialogComponent, options);
            }
          );
        }



      } else {

        this.mostrar = true;
        this._CchService.getFact(Cups, moment(FecInicio).set({hour:1,minute:0}).format(), moment(FecFin).format()).subscribe(
          async data => {
            this.mostrar = false;
            this.datosCch = this._funcionesService.decodificarToken(data);
            if(this.datosCch.listaCCH == undefined){ //si no muestra datos de FACT hacemos la llamada en TCURVAS
              this.isTcurvas = true;
              this.busquedaCchPorFechas(FecInicio, FecFin, Cups, CodigoContrato, tipoGrafico, true);
            }
            if (this.datosCch !== undefined) {
              this.chartOptions = {
                lang: this.options,
                chart: { type: 'line',
                        zoomType: 'xy',
                        events: {
                          load: function(event) {
                            this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                          }
                        }
                },
                title: { text: 'CCH' },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                      ]
                    }
                  }
                },
                subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
                yAxis: { title: { text: 'Consumo (kWh)' } },
                series: [],
                credits: {
                  enabled: false
                }
              };
              /**
               * COMPARATIVAS
               */
              if (tipoGrafico === 'Comparativa') {
                this.arrayCategorias = [];
                for (const i of this.datosCch) {
                  // Para cada CUPS cargo los datos vacíos
                  const arrayActiva = [];
                  const arrayReactiva = [];
                  for (const e of i.ListaCCH) {
                    arrayActiva.push(Number(e.ACTIVA));
                    arrayReactiva.push(Number(e.REACTIVA));
                    this.arrayCategorias.push(moment(e.FECHAMED).format('DD MMM YYYY (HH:mm)').toString());
                  }
                  seriesAdd.push(
                    { name: `Activa ${i.CodigoCUPS}`, data: arrayActiva },
                    { name: `Reactiva ${i.CodigoCUPS}`, data: arrayReactiva }
                  );
                }
                this.options = JSON.parse(this._GraficosService.graficosOption);
                this.chartOptions = {
                  lang: this.options,
                  chart: { type: 'line',
                        zoomType: 'xy',
                        events: {
                          load: function(event) {
                            this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                          }
                        }
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [
                          'printChart',
                          'separator',
                          'downloadPNG',
                          'downloadJPEG',
                          'downloadPDF',
                          'downloadSVG',
                          'separator',
                          'downloadCSV',
                          'downloadXLS'
                        ]
                      }
                    }
                  },
                  saveInstance(chartInstance): void { this.chart = chartInstance; },
                  title: { text: 'CCH' },
                  subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
                  xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
                  yAxis: { title: { text: 'Consumo (kWh)' } },
                  series: seriesAdd,
                  credits: {
                    enabled: false
                  }
                };
                this.oneToOneFlag = this.updateFlag = true;
              }
            }
          },
          (error) => {
            this.mostrar = false;
            const options = {
              width: '400px',
              data: {
                texto: 'Se ha producido un error en la carga de las curvas.',
                titulo: 'Curvas de Carga Horaria'
              }
            };
            this.dialog.open(LoginDialogComponent, options);
          }
        );
      }
    }
  }

  CalculoSeleccionFechasInicio(FechaInicio) {
    this.FecFin = moment().format();
    this.FecFinAux = moment(this.FecInicio)
      .add(this.mesesgraficocch, 'M')
      .format();
    if (moment(this.FecFinAux).diff(moment(), 'days') === 0) {
      this.FecFinAux = moment().format();
    }
    this.FecFin = this.FecFinAux;
    this.busquedaCchPorFechas(moment(FechaInicio).format(), this.FecFinAux, this.arrayCupsSeleccionados, this.arrayCodigoContratoSeleccionados, this.tipoGraficoSelected, false);
  }

  CalculoSeleccionFechasFin(FechaFin) {
    // this.FecFinAux = moment().format();
    this.busquedaCchPorFechas(this.FecInicio, moment(FechaFin).format(), this.arrayCupsSeleccionados, this.arrayCodigoContratoSeleccionados, this.tipoGraficoSelected, false);
  }

  BusquedaNuevoCups() {

    this.arrayCodigoContratoSeleccionados = [];
    this.arrayCupsSeleccionados = [];

    for (const i of this.selectedCups.value) {
      // if(this.isTcurvas){
        this.arrayCodigoContratoSeleccionados.push(i.Cups.CodigoContrato);
      // }else{
        this.arrayCupsSeleccionados.push({"CodigoCups":i.Cups.CodigoCups});
      // }
      if (this.arrayCodigoContratoSeleccionados.length >= environment.maxCUPSgraficoCCH) {
        this.bloqueocheckCCH = true;
      }else {
        this.bloqueocheckCCH = false;
      }
    }
        // console.log(this.arrayCupsSeleccionados);
    this.busquedaCchPorFechas(this.FecInicio, this.FecFinAux, this.arrayCupsSeleccionados, this.arrayCodigoContratoSeleccionados, this.tipoGraficoSelected, false);
  }

  search(query: string) {
    query=query.toLowerCase();
    const result = this.select(query);
    this.cupsFiltered = result;
  }

  select(query: string): string[] {
    const result: string[] = [];
    for (const a of this.arrayCups) {
      if (a.Cups.CodigoCups.toLowerCase().indexOf(query) > -1) {
        result.push(a)
      }
    }
    return result;
  }

  selectAll(checkAll) {
    if (checkAll) {
      // Desmarcar todos
      this.selectedCups.reset();
      this.options = JSON.parse(this._GraficosService.graficosOption);
      this.chartOptions = {
        lang: this.options,
        chart: { type: 'line',
                  zoomType: 'xy',
                  events: {
                  load: function(event) {
                    this.renderer.image("/assets/img/logo.png",10,10,150,50).attr({zIndex: 99999999}).add();
                  }
                }
              },
        title: { text: 'CCH' },
        subtitle: { text: this._GraficosService.traduccionDeTextos(localStorage.getItem('idioma'), 'Selecciona dentro del gráfico para hacer zoom') },
        xAxis: { categories: [], crosshair: true, description: 'Descripción' },
        yAxis: { title: { text: 'Consumo (kWh)' } },
        series: []
      };
    } else {
      // Marcar todos
      this.selectedCups.setValue(this.arrayCups);
      for (const i of this.selectedCups.value) {
        this.arrayCupsSeleccionados.push({"CodigoCups":i.Cups.CodigoCups});
        this.arrayCodigoContratoSeleccionados.push(i.Cups.CodigoContrato);
      }
      this.busquedaCchPorFechas(this.FecInicio, this.FecFin, this.arrayCupsSeleccionados, this.arrayCodigoContratoSeleccionados ,this.tipoGraficoSelected, false);
    }
  }

}
